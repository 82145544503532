import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/miniprogram.vue')
  },
  {
    path: '/RAW2DNG',
    name: 'RAW2DNG',
    component: () => import('../views/RAW2DNG.vue')
  },
  {
    path: '/CaptureCounter',
    name: 'CaptureCounter',
    component: () => import('../views/CaptureCounter.vue')
  },
  {
    path: '/DTT',
    name: 'DTT',
    component: () => import('../views/DTT.vue')
  },
  {
    path: '/TimelapseToolbox',
    name: 'TimelapseToolbox',
    component: () => import('../views/TimelapseToolbox.vue')
  },
  {
    path: '/LandscapePixelWallpaper',
    name: 'LandscapePixelWallpaper',
    component: () => import('../views/LandscapePixelWallpaper.vue')
  },
  {
    path: '/miniprogram',
    name: 'miniprogram',
    component: () => import('../views/miniprogram.vue')
  },
  {
    path: '/MeteorMaster',
    name: 'MeteorMaster',
    component: () => import('../views/MeteorMaster.vue')
  },
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/sogousiteverification.txt',
    redirect: '/sogousiteverification.txt'
  },
  {
    path: '/policy/CaptureCounter',
    component: () => import('../views/policy/CaptureCounter.vue')
  },
  {
    path: '/policy/MeteorMasterAI',
    component: () => import('../views/policy/MeteorMasterAI.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
//创建sitemap.xml
function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;
 
    if (route.path !== '*') {
      array.push(path);
    }
 
    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }
 
    return array;
  }, []);
}
 
 
// getRoutesList(router.options.routes, 'https://zigamiklic.com');
function getRoutesXML() {
  const list = getRoutesList(router.options.routes, 'https://photohelper.cn/')
    .map(route => `<url><loc>${route}</loc></url>`)
    .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}
 
// console.log(getRoutesXML())

export default router
