<template>
  <div id="app">
    <div id="nav">
      <div class="logobox">
        <img  draggable="false" src="@/assets/img/logo.png" alt="" srcset="">
        <span class="name">奔跑的龟斯</span>
      </div>
      <router-link to="/miniprogram" :class="$route.path == '/miniprogram'?'active1':''">小程序</router-link>
      <router-link to="/RAW2DNG" :class="$route.path == '/RAW2DNG'?'active3':''">DNG转码工具</router-link>
      <router-link to="/LandscapePixelWallpaper" :class="$route.path == '/LandscapePixelWallpaper'?'active5':''">风光Pixel影像日历</router-link>
      <router-link to="/DTT" :class="$route.path == '/DTT'?'active2':''">DNG Timelapse Tool</router-link>
      <router-link to="/TimelapseToolbox" :class="$route.path == '/TimelapseToolbox'?'active2':''">Timelapse Toolbox</router-link>
      <router-link to="/MeteorMaster" :class="$route.path == '/MeteorMaster'?'active6':''">Meteor Master AI</router-link>
      <router-link to="/CaptureCounter" :class="$route.path == '/CaptureCounter'?'active4':''">查相机快门数</router-link>
      <router-link class="hidden-xs" to="/About" :class="$route.path == '/About'?'active6':''">关于作者</router-link>
    </div>
    <div id="navxs">
      <img  draggable="false" class="logo" src="@/assets/img/logo.png" alt="" srcset="">
      <span class="name" @click="showmenu = !showmenu">其他工具</span>
      <div class="menu" v-if="showmenu">
        <router-link to="/miniprogram" :class="$route.path == '/miniprogram'?'active1':''">小程序</router-link>
        <router-link to="/RAW2DNG" :class="$route.path == '/RAW2DNG'?'active3':''">DNG转换工具</router-link>
        <router-link to="/LandscapePixelWallpaper" :class="$route.path == '/LandscapePixelWallpaper'?'active5':''">风光Pixel影像日历</router-link>
        <router-link to="/DTT" :class="$route.path == '/DTT'?'active2':''">DNG Timelapse Tool 延时摄影自动化过渡工具</router-link>
        <router-link to="/TimelapseToolbox" :class="$route.path == '/TimelapseToolbox'?'active2':''">Timelapse Toolbox 强大的批量重命名工具</router-link>
        <router-link to="/MeteorMaster" :class="$route.path == '/MeteorMaster'?'active6':''">Meteor Master AI 智能流星检测</router-link>
        <router-link to="/CaptureCounter" :class="$route.path == '/CaptureCounter'?'active4':''">查相机快门数</router-link>
      </div>
    </div>
    <div id="main">
      <router-view/>
    </div>
    <div id="footer">
      <div class="container">
        <div class="row text-center">
          <p>
            <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" >苏ICP备2022014410号</a>
          </p>
          <p>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002003991"><img  draggable="false" src="@/assets/gongan.png"/>苏公网安备 32059002003991号</a>
          </p>
        </div>
      </div>
    </div>
    <div id="contact"> 
      <a href="https://weibo.com/mordom0404" target="_blank"><img  draggable="false" class="social" src="@/assets/img/miniprogram/weibo.png" alt="" srcset=""></a>
      <a href="https://space.bilibili.com/401484" target="_blank"><img  draggable="false" class="social" src="@/assets/img/miniprogram/bilibili.png" alt="" srcset=""></a>
      <a href="https://mordom.tuchong.com/" target="_blank"><img  draggable="false" class="social" src="@/assets/img/miniprogram/tuchong.png" alt="" srcset=""></a>
      <a href="mailto:mordom@foxmail.com" target="_blank"><img  draggable="false" class="social" src="@/assets/img/miniprogram/mail.png" alt="" srcset=""></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'App',
  props: {
    
  },
  watch:{
    $route(){
      this.showmenu = false
    }
  },
  data(){
    return {
      showmenu:false
    }
  },
  mounted(){
    
  },
  methods:{
    
  }

}
</script>
<style lang="less">
@import "./assets/bootstrap/css/bootstrap.css";
#app {
  font-family: "SourceHanSansCN-Light","Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
*{
  transition-property: color,background-color,border,opacity,transform;
  transition-duration: 0.3s;
  transition-timing-function:ease;
  position: relative;
  box-sizing: border-box;
}
html,body{
  background: #fff;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: "SourceHanSansCN-Light";
  src: url("./assets/fonts/SourceHanSansCN-Light.woff2") format("woff2"),
       url("./assets/fonts/SourceHanSansCN-Light.woff") format("woff"),
       url("./assets/fonts/SourceHanSansCN-Light.eot") format("embedded-opentype"),
       url("./assets/fonts/SourceHanSansCN-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gilroy";
  src: url("./assets/fonts/Gilroy-Thin-13.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
//图片懒加载相关
img[lazy]{
  transition-duration: 1s;
}
img[lazy=loading] {
  opacity: 0;
}
img[lazy=loaded] {
  opacity: 1!important;
}
.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
#nav {
  .flex;
  height: 80px;
  padding: 0;
  width: 100%;
  color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 100;
  .logobox{
    flex-grow: 1;
    .flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    img{
      height: 40px;
      margin-right: 15px;
    }
    .name{
      font-size: 24px;
      font-weight: 100;
      line-height: 1;
      top: -2px;
      cursor: default;

    }
  }
  a {
    font-weight: normal;
    color: #fff;
    padding: 0 20px;
    margin: 0 10px;
    text-decoration: none;
    font-size: 16px;
    text-shadow: 0px 0px 20px rgba(0,0,0,0.5);
    border-radius: 15px;
    &.active1{
      background-color: #fff;
      color: #319493;
      line-height: 30px;
      text-shadow: none;
    }
    &.active2{
      background-color: #fff;
      color: #e3b23e;
      line-height: 30px;
      text-shadow: none;
    }
    &.active3{
      background-color: #fff;
      color: #56a371;
      line-height: 30px;
      text-shadow: none;
    }
    &.active4{
      background-color: #fff;
      color: #427ea4;
      line-height: 30px;
      text-shadow: none;
    }
    &.active5{
      background-color: #fff;
      color: #005897;
      line-height: 30px;
      text-shadow: none;
    }
    &.active6{
      background-color: #fff;
      color: #5f9997;
      line-height: 30px;
      text-shadow: none;
    }
    &:hover{
      transform: scale(1.02);
    }
  }
}
#navxs {
  .flex;
  display: none;
  height:50px;
  width: 100%;
  color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 100;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  .logo{
    height: 40px;
    z-index: 20;
  }
  .name{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    font-size: 24px;
    font-weight: 100;
    line-height: 1;
    cursor: default;
    z-index: 20;
  }
  .menu{
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 50px 10px 5px;
    z-index: 10;
    background-color: rgba(255,255,255,0.4);
    box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
    backdrop-filter: blur(30px);
    a {
      display: block;
      font-weight: normal;
      color: #fff;
      padding: 0 20px;
      line-height: 2.5;
      text-decoration: none;
      font-size: 16px;
      text-shadow: 0px 0px 20px rgba(0,0,0,0.5);
      border-bottom: 1px solid #fff;
      &.active1,&.active2,&.active3,&.active4,&.active5{
        font-weight: bold;
        color: #fff;
      }
      &:nth-last-child(1){
        border-bottom: none;
      }
    }
  }
}
@media screen and (max-width:1500px){
  #nav{
    display: none;
  }
  #navxs{
    display: flex;
  }
}
#main{
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  min-height: 80vh;
}
#footer{
  height: 80px;
  margin-top: 100px;
  a{
    text-align: center;
    display:inline-block;
    text-decoration:none;
    height:30px;
    line-height:30px;
    color: #999;
  }
}
#contact{
  position: fixed;
  right: 20px;
  bottom: 40px;
  .flex;
  flex-direction: column;
  a{
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(255,255,255,0.6);
    margin-top: 10px;
    .flex;
    justify-content: center;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    &:hover{
      transform: scale(1.02);
    }
  }
  img{
    width: 25px;

  }
}
</style>
