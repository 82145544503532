import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//懒加载
import VueLazyload from 'vue-lazyload'
//为打包的静态页面分配title和meta标签
import MetaInfo from 'vue-meta-info'
import "@/assets/cssgg.css"


//懒加载
Vue.use(VueLazyload, {
  preLoad: 1.3,
  observer: true,
  // lazyComponent: true,
  attempt: 1,     // 加载图片数量
  // throttleWait:2000
})
Vue.use(MetaInfo)
Vue.config.productionTip = false

//全局事件总线
Vue.prototype.$eventHub= Vue.prototype.$eventHub || new Vue()
Date.prototype.format = function(fmt) {
  //author: meizz
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  return fmt;
};
new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')

Vue.mixin({
  methods: {
    getUrlParams(text) { // 不传text返回所有值，否则返回对应值
      var url = window.location.search;
      if (url.indexOf('?') == 1) { return false; }
      url = url.substr(1);
      url = url.split('&');
      var name = text || '';
      var urlobj = {}
      var nameres;
      // 获取全部参数及其值
      for(var i=0;i<url.length;i++) {
        var info = url[i].split('=');
        var obj = {};
        obj[info[0]] = decodeURI(info[1]);
        Object.assign(urlobj,obj)
      }
      // 如果传入一个参数名称，就匹配其值
      if (name) {
        nameres = urlobj[name]
      } else {
        nameres = urlobj;
      }
      // 返回结果
      return nameres;
    }
  },
})